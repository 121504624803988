import { useCallback, useMemo, useState } from 'react'
import LeadrAssessmentContext from './LeadrAssessmentContext'
import { breakpoints } from 'stylesheets/breakpoints'
import useMediaQuery from 'hooks/useMediaQuery'
import { css } from '@emotion/react'
import { colors, sizes, spacings } from 'stylesheets/theme'
import WelcomeStep from './WelcomeStep'
import AssessmentStepWrapper from './AssessmentStepWrapper'
import AssessmentQuestion from './AssessmentQuestion'
import Axios from 'axios'

interface LeadrAssessmentProps {
  questions: any
  submitAssessmentUrl: string
}

const LeadrAssessmentWrapperStyle = css({
  minHeight: `calc(100vh - ${sizes.headerHeight})`,
  marginTop: sizes.headerHeight,
  padding: spacings.grid_gap_basis_num * 2,
  backgroundColor: colors.backgrounds.white,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  'mark:not(.optional)': {
    backgroundColor: colors.backgrounds.teal,
    color: colors.green_dark,
  },
  'mark.optional': {
    color: colors.text.text_7c,
    fontSize: 18,
    lineHeight: 1.2,
    background: 'none',
  },
})

const MobileLeadrAssessmentWrapperStyle = css({
  maxWidth: breakpoints.xs,
  justifyContent: 'space-between',
  padding: 0,
})

export default function LeadrAssessment({
  questions,
  submitAssessmentUrl,
}: LeadrAssessmentProps): JSX.Element {
  const [currentStep, setCurrentStep] = useState(0)
  /**
   * This is the answers that will be passed back to BE for scoring
   * This array starts with x number of empty arrays, where x is the number of questions
   * i.e.
   * [
      [
        {
          "answer": "Analyzing the task and breaking it down into smaller, more manageable parts that I can prioritize"
        },
        {
          "answer": "Consulting with key stakeholders to understand their needs, expectations and concerns"
        },
        {
          "answer": "Learning by doing and navigating challenges as they arise"
        },
        {
          "answer": "Mapping out the simplest version of the solution and how to implement it"
        }
      ],
      [],
      []...
    ]
   */
  const [userAnswers, setUserAnswers] = useState<string[][]>(
    new Array(questions.length).fill([]),
  )

  const [finalAnswers, setFinalAnswers] = useState<string[][]>(
    new Array(questions.length).fill([]),
  )
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)
  const wrapperStyle = useMemo(
    () => [
      LeadrAssessmentWrapperStyle,
      isMobile && MobileLeadrAssessmentWrapperStyle,
    ],
    [isMobile],
  )

  const onAnswerChange = useCallback(
    (answer: any[], questionIndex: number) => {
      const newAnswers = [...userAnswers]
      const newFinalAnswers = [...finalAnswers]
      newAnswers[questionIndex] = answer
      /**
       * Translates all userAnswers to this format: 
       * [
          ['a', 'c', 'd', 'b'],
          ['a', 'c', 'b', 'd'],
          ['c', 'd', 'a', 'b'],
          ['c', 'a', 'b', 'd'],
          ['a', 'c', 'b', 'd'],
          ['c', 'b', 'd', 'a'],
          ['a', 'c', 'b', 'd'],
          ['b', 'a', 'd', 'c'],
          ['d', 'c', 'b', 'a'],
          ['a', 'c', 'b', 'd'],
          ['c', 'b', 'a', 'd'],
          ['b', 'd', 'a', 'c'],
          ['b', 'a', 'c', 'd'],
          ['a', 'c', 'b', 'd'],
          ['b', 'c', 'd', 'a'],
        ]
      */
      newFinalAnswers[questionIndex] = answer.map((ans) => {
        const letterIndex = questions[questionIndex].answers.findIndex(
          (nonRankedAnswer) =>
            JSON.stringify(nonRankedAnswer.answer) ==
            JSON.stringify(ans.answer),
        )

        const letter = String.fromCharCode(letterIndex + 'A'.charCodeAt(0))

        return letter
      })
      setUserAnswers(newAnswers)
      setFinalAnswers(newFinalAnswers)
    },
    [userAnswers, setUserAnswers, setFinalAnswers],
  )

  /**
   * Submit form with final answers
   */
  const onSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true)
      await Axios.post(submitAssessmentUrl, {
        authenticity_token: window.authenticity_token,
        assessment: {
          answers: finalAnswers,
        },
      }).then((response) => {
        setIsSubmitting(false)
        const {
          data: { data },
        } = response
        if (data?.redirect) {
          window.location.href = data.redirect
        } else {
          window.flash(
            'Something went wrong trying to submit leadr assessment',
            'alert',
          )
        }
      })
    } catch (error) {
      setIsSubmitting(false)
      window.flash(
        `Something went wrong trying to submit leadr assessment: ${error}`,
        'alert',
      )
    }
  }, [finalAnswers, submitAssessmentUrl])

  const onNextStep = useCallback(
    (isSubmit = false) => {
      if (currentStep !== 0) {
        const submittedAnswers = finalAnswers[currentStep - 1]
        if (
          submittedAnswers.length !== 4 ||
          submittedAnswers.some(
            (ans) => ans !== 'A' && ans !== 'B' && ans !== 'C' && ans !== 'D',
          )
        ) {
          window.flash(
            'Please complete ranking all of the options before proceeding to the next step',
            'alert',
          )
          return
        }
      }

      window.scrollTo(0, 0)

      if (isSubmit) {
        onSubmit()
      } else {
        setCurrentStep((prev) => prev + 1)
      }
    },
    [setCurrentStep, onSubmit, currentStep],
  )

  const onBackStep = useCallback(() => {
    window.scrollTo(0, 0)
    setCurrentStep((prev) => prev - 1)
  }, [setCurrentStep])

  const steps = [
    <WelcomeStep key="welcome" onLetsGo={() => onNextStep(false)} />,
    ...questions.map((question, index) => {
      return (
        <AssessmentQuestion
          key={question.question}
          question={question.question}
          answers={userAnswers[index]}
          defaultAnswers={question.answers}
          setAnswers={(answer: string[]) => onAnswerChange(answer, index)}
          defaultIsMobile={isMobile}
        />
      )
    }),
  ]

  return (
    <LeadrAssessmentContext.Provider
      value={{
        isMobile,
        maxSteps: steps.length - 1,
      }}>
      <div css={wrapperStyle}>
        <AssessmentStepWrapper
          isMobile={isMobile}
          maxSteps={steps.length - 1}
          currentStep={currentStep}
          onNext={onNextStep}
          onBackStep={onBackStep}
          isSubmitting={isSubmitting}>
          {steps[currentStep]}
        </AssessmentStepWrapper>
      </div>
    </LeadrAssessmentContext.Provider>
  )
}
