import React, { forwardRef } from 'react'
import { css } from '@emotion/react'
import { colors, spacings } from 'stylesheets/theme'
import { defaultLinkStyle } from 'components/Link'

export enum Variant {
  UNSTYLED = 'not styled',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  LINK = 'link',
  TERTIARY = 'tertiary',
}

const disabledStyle = css({
  opacity: 0.6,
  cursor: 'not-allowed',
  pointerEvents: 'none',
})

const defaultButtonStyle = css({
  borderRadius: spacings.radius,
  fontFamily: '"Lato", sans-serif',
  textTransform: 'uppercase',
  textDecoration: 'none',
  outline: 'none',
  cursor: 'pointer',
  minWidth: '90px',
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '0.5px',
  fontWeight: 'bold',
  // 22px is the correct left & right number.
  // Designs call for 24px from text to edge of button.
  // Buttons have a 2px border, so that leaves 22px for padding.
  padding: '10px 22px',
  textAlign: 'center',
  border: '2px solid',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 0, // required to override Safari's default button styling
})

const primaryButtonStyle = css({
  backgroundColor: colors.buttons.teal,
  borderColor: colors.buttons.teal,
  color: colors.backgrounds.white,
  '&[disabled]': {
    opacity: 0.6,
    cursor: 'not-allowed',
  },
  '&:not([disabled]):focus, &:not([disabled]):hover': {
    backgroundColor: colors.teal_dark,
    borderColor: colors.teal_dark,
  },
})

const secondaryButtonStyle = css({
  background: 'transparent',
  borderColor: colors.buttons.teal,
  color: colors.teal_dark,
  '&[disabled]': {
    opacity: 0.6,
    cursor: 'not-allowed',
  },
  '&:not([disabled]):focus, &:not([disabled]):hover': {
    color: colors.teal_dark,
    borderColor: colors.teal_dark,
    backgroundColor: colors.backgrounds.gray,
  },
})

const tertiaryButtonStyle = css({
  background: colors.backgrounds.gray,
  borderColor: colors.backgrounds.gray,
  color: colors.text.text_5,
  '&:focus, &:hover': {
    borderColor: colors.borders.gray,
    backgroundColor: colors.borders.gray,
  },
})

const notStyledButtonStyle = css({
  border: 'none',
  backgroundColor: 'transparent',
  padding: 0,
  cursor: 'pointer',
  textAlign: 'inherit',
  '& :hover': {
    opacity: 0.8,
  },
  '&:focus': {
    outline: `2px solid ${colors.teal}`,
    outlineOffset: '2px',
  },
})

const smallButtonStyle = css({
  padding: '4px 24px',
})

const ContentWithIconWrapperStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
})

interface BaseProps {
  variant?: Variant
  endIcon?: React.ReactNode
  startIcon?: React.ReactNode
  small?: boolean
  disabled?: boolean
}

type ButtonProps = BaseProps & React.ButtonHTMLAttributes<HTMLButtonElement>
type AnchorProps = BaseProps & React.AnchorHTMLAttributes<HTMLAnchorElement>

export enum ButtonComponentType {
  LINK = 'link',
  BUTTON = 'button',
}

export type ButtonComponentProps<T extends ButtonComponentType> = {
  as?: T
} & (T extends ButtonComponentType.BUTTON ? ButtonProps : AnchorProps)

export default forwardRef(function Button<T extends ButtonComponentType>(
  {
    variant,
    children,
    endIcon,
    startIcon,
    as,
    small,
    disabled,
    ...props
  }: ButtonComponentProps<T>,
  ref,
): JSX.Element {
  let style = null
  switch (variant) {
    case Variant.LINK:
      style = [notStyledButtonStyle, defaultLinkStyle]
      break
    case Variant.UNSTYLED:
      style = notStyledButtonStyle
      break
    case Variant.PRIMARY:
      style = [defaultButtonStyle, primaryButtonStyle]
      break
    case Variant.SECONDARY:
      style = [defaultButtonStyle, secondaryButtonStyle]
      break
    case Variant.TERTIARY:
      style = [defaultButtonStyle, tertiaryButtonStyle]
      break
    default:
      break
  }

  if (small) {
    style.push(smallButtonStyle)
  }

  if (disabled) {
    style.push(disabledStyle)
  }

  interface ContentProps {
    startIcon?: ButtonComponentProps<T>['startIcon']
    endIcon?: ButtonComponentProps<T>['endIcon']
    children: ButtonComponentProps<T>['children']
  }

  function Content({
    startIcon,
    endIcon,
    children,
  }: ContentProps): JSX.Element {
    if (startIcon || endIcon) {
      return (
        <div css={ContentWithIconWrapperStyle}>
          {startIcon && startIcon}
          {children}
          {endIcon && endIcon}
        </div>
      )
    }

    return <>{children}</>
  }

  if (as === ButtonComponentType.LINK) {
    return (
      <a {...(props as AnchorProps)} css={style} ref={ref}>
        <Content startIcon={startIcon} endIcon={endIcon}>
          {children}
        </Content>
      </a>
    )
  } else {
    return (
      <button
        {...(props as ButtonProps)}
        css={style}
        ref={ref}
        disabled={disabled}>
        <Content startIcon={startIcon} endIcon={endIcon}>
          {children}
        </Content>
      </button>
    )
  }
})
