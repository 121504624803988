import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { spacings, colors } from 'stylesheets/theme'
import Step from './Step'
import { css } from '@emotion/react'
import Pill, { PillColor } from 'components/Pill/Pill'
import Container from 'components/Container/Container'
import Button, { Variant } from 'components/Button/Button'
import RightArrowWhite from 'images/icons/arrow_right_w.svg'
import LeftArrow from 'images/icons/arrow_left.svg'
import { Caption } from 'components/Typography'

import { useContentDimensions } from 'hooks/useDimensions'

export enum Breakpoints {
  NARROW = 1200,
  MEDIUM = 1300,
  WIDE = 1530,
}
export interface TStep {
  title: string
  description: string
  caption?: string
  images?: {
    narrow: string
    medium: string
    wide: string
    extraWide: string
  }
  content?: React.ReactNode
}

interface GuideWizardProps {
  steps?: TStep[]
  selectedStepIndex?: number
}

const captionStyle = css({
  marginBottom: spacings.grid_gap_basis_num / 2,
  fontStyle: 'italic',
})

const defaultPanelStyle = css({
  height: '962px',
  alignSelf: 'flex-start',
  borderRadius: spacings.large_radius,
  backgroundColor: colors.backgrounds.white,
  display: 'flex',
  flexDirection: 'column',
})

const leftPanelStyle = css({
  width: '412px',
  alignItems: 'center',
  padding: spacings.grid_gap_basis_num * 2,
  gap: spacings.grid_gap_basis,
})

const rightPanelStyle = css({
  width: '100%',
  padding: spacings.grid_gap_basis_num * 3,
  gap: spacings.grid_gap_basis_num * 2,
  justifyContent: 'space-between',
})

const defaultButtonStyle = css({
  height: spacings.grid_gap_basis_num * 3.5,
  position: 'absolute',
})

const previousStepButtonStyle = css({
  minWidth: '170px',
  left: 0,
})

const nextStepButtonStyle = css({
  minWidth: '140px',
  right: 0,
})

const buttonsBoxWrapperStyle = css({
  width: '100%',
  height: spacings.grid_gap_basis_num * 3.5,
  position: 'relative',
})

const stepImageStyle = css({
  border: `1px solid ${colors.borders.gray}`,
  borderRadius: 6,
  objectFit: 'cover',
  width: '100%',
  height: 750,
})

export default function GuideWizard({
  steps = [],
  selectedStepIndex = 0,
}: GuideWizardProps): JSX.Element {
  const closestBreakpoint = useContentDimensions(
    ...(Object.values(Breakpoints).filter(Number.isInteger) as number[]),
  )

  const [currentStep, setCurrentStep] = useState<TStep>(steps[0])

  useEffect(() => {
    setCurrentStep(steps[selectedStepIndex])
  }, [steps])

  const currentStepIndex = useMemo(
    () => steps.indexOf(currentStep),
    [currentStep, steps],
  )

  const onNextStep = useCallback(
    () => onSelectStep(currentStepIndex + 1),
    [currentStepIndex],
  )

  const onPreviousStep = useCallback(
    () => onSelectStep(currentStepIndex - 1),
    [currentStepIndex],
  )

  const onSelectStep = useCallback(
    (stepIndex) => {
      setCurrentStep(steps[stepIndex])
    },
    [steps],
  )

  const currentStepImage: string = useMemo(() => {
    if (!currentStep.images) {
      return null
    }

    switch (closestBreakpoint) {
      case Breakpoints.NARROW:
        return currentStep.images.narrow
      case Breakpoints.MEDIUM:
        return currentStep.images.medium
      case Breakpoints.WIDE:
        return currentStep.images.wide
      default:
        return currentStep.images.extraWide
    }
  }, [currentStep, closestBreakpoint])

  return (
    <Container
      css={{
        gap: '30px',
      }}>
      {/* left panel */}
      <div css={[defaultPanelStyle, leftPanelStyle]}>
        {steps.map((step, index) => (
          <Step
            key={index}
            stepNumber={index + 1}
            title={step.title}
            selected={currentStepIndex === index}
            onClick={() => onSelectStep(index)}
          />
        ))}
      </div>
      {/* right panel */}
      <div css={[defaultPanelStyle, rightPanelStyle]}>
        <Container>
          <Pill color={PillColor.GREEN} dark size="large">
            Step {currentStepIndex + 1}
          </Pill>
          <h5>{currentStep.description}</h5>
        </Container>
        <div
          css={{
            flexGrow: 1,
          }}>
          {currentStep.caption && (
            <Caption css={captionStyle}>{currentStep.caption}</Caption>
          )}
          {currentStep.content ? (
            currentStep.content
          ) : (
            <img
              src={currentStepImage}
              alt={currentStep.title}
              css={stepImageStyle}></img>
          )}
        </div>
        <div css={buttonsBoxWrapperStyle}>
          {currentStepIndex !== 0 && (
            <Button
              variant={Variant.TERTIARY}
              startIcon={<LeftArrow />}
              css={[defaultButtonStyle, previousStepButtonStyle]}
              onClick={onPreviousStep}>
              Previous Step
            </Button>
          )}
          {currentStepIndex !== steps.length - 1 && (
            <Button
              variant={Variant.PRIMARY}
              endIcon={<RightArrowWhite />}
              css={[defaultButtonStyle, nextStepButtonStyle]}
              onClick={onNextStep}>
              Next Step
            </Button>
          )}
        </div>
      </div>
    </Container>
  )
}
