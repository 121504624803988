import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  CategoryScale,
  LinearScale,
  PolarAreaController,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Chart } from 'react-chartjs-2'
import { IAssessmentLeadrResults } from './LeadrIndexReport.types'
import { colors, fonts } from 'stylesheets/theme'
import { SChartStyle } from './LeadrResultChart.styles'
import { useCallback, useMemo, useState } from 'react'
import { css } from '@emotion/react'
import { ILeadrResultChartStrings } from './LeadrResultChart.types'
import Tooltip from 'components/Tooltip'
import { translateAllEnLiterals } from 'utils/translateEnLiterals'
import { ICoords } from 'components/Tooltip/Tolltip.types'

/*
Chart.js: https://www.chartjs.org/docs/latest/
React-ChartJS-2: https://react-chartjs-2.js.org/
ChartDataLabels: https://chartjs-plugin-datalabels.netlify.app/guide/#table-of-contents
PolarArea chart example: https://codesandbox.io/p/devbox/reactchartjs-react-chartjs-2-default-n8obt?embed=1&file=%2FApp.tsx%3A18%2C34
*/

ChartJS.register(
  RadialLinearScale,
  CategoryScale,
  LinearScale,
  ArcElement,
  PolarAreaController,
)

interface ILeadrResultChartProps {
  strings?: ILeadrResultChartStrings
  results: IAssessmentLeadrResults
  showSliceTooltipOnHover?: boolean
  width?: number
  height?: number
}

function leadrComponentFromHoverElements(elements: any[]): string | undefined {
  if (elements.length === 0) return

  for (const element of elements) {
    if (
      element?.element &&
      element?.element?.$context &&
      element?.element?.$context.dataIndex !== undefined
    ) {
      return Object.keys(colors.leadr)[element?.element?.$context.dataIndex]
    }
  }

  return undefined
}

function capitalize(str: string) {
  if (str.length === 0) return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export default function LeadrResultChart({
  strings,
  results,
  showSliceTooltipOnHover = false,
  width = 350,
  height = 350,
}: ILeadrResultChartProps): JSX.Element {
  const style = useMemo(() => {
    return css({
      width: width,
      height: height,
    })
  }, [width, height])
  const keys = Object.keys(results)
  const values = Object.values(results)
  const data = {
    datasets: [
      {
        label: 'LEADR',
        data: values,
        backgroundColor: keys.map((leadrElement) => {
          return colors.leadr[leadrElement]
        }),
        borderWidth: 3,
      },
    ],
  }

  const [hoveredComponent, setHoveredComponent] = useState<string | undefined>()
  const [tooltipPosition, setTooltipPosition] = useState<ICoords>()

  const onHover = useCallback(
    (...args) => {
      const leadrComponent = leadrComponentFromHoverElements(args[1])
      let position = tooltipPosition
      setHoveredComponent(leadrComponent)
      if (leadrComponent && args[0] && args[0].native) {
        position = {
          x: args[0].native.clientX,
          y: args[0].native.clientY,
        }
        setTimeout(() => setTooltipPosition(position), 1)
      }
    },
    [setTooltipPosition, setHoveredComponent],
  )
  const options = useMemo(() => {
    return {
      onHover: onHover,
      layout: {
        padding: 0,
      },
      scales: {
        r: {
          angleLines: { display: false },
          ticks: { display: false },
          grid: { display: false },
          pointLabels: { display: true, centerPointLabels: true },
        },
      },
      plugins: {
        datalabels: {
          color: colors.backgrounds.white,
          font: {
            family: fonts.poppins,
            size: 32,
            weight: 700,
          },
          formatter: function (value, context) {
            return value > 0
              ? keys[context.dataIndex].charAt(0).toUpperCase()
              : ''
          },
        },
      },
    }
  }, [keys, onHover])

  return (
    <div css={[SChartStyle, style]}>
      {showSliceTooltipOnHover ? (
        <Tooltip
          show={!!hoveredComponent}
          overrideCoords={tooltipPosition}
          text={translateAllEnLiterals(
            results[hoveredComponent] ?? 0 > 1
              ? strings.tooltips.report_chart_slices.plural
              : strings.tooltips.report_chart_slices.single,
            {
              count: results[hoveredComponent] ?? 0,
              component: capitalize(hoveredComponent ?? ''),
            },
          )}
        />
      ) : null}
      <Chart
        type={'polarArea'}
        data={data}
        options={options}
        plugins={[ChartDataLabels]}
      />
    </div>
  )
}
